* {
  font-family: 'Quicksand';
}

.bold {
  font-weight: 600;
}

.textCenter {
  text-align: center;
}

.App {
  width: 100%;
  height: 100%;
  background-color: #002b36;
  position: absolute;
}

.Game {
  width: 100%;
  height: 100%;
  font-family: 'Quicksand';
  overflow: hidden;
  overflow-y: auto;
}
  .Game .gameBody {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

.gameTitle {
  margin-top: 8px;
  margin-bottom: 10px;
}
.gameTitle * {
  font-family: 'Chlorinar';
  text-align: center;
  font-size: 60px;
  color: #d33682;
  margin-top: 2px;
}
  .gameTitle .back {
    color: #4444ff;
  }
  .gameTitle .front {
    color: #ff5733;
    position: relative;
    top: -58px;
    right: 3px;
    height: 0;
  }

.gamePicker {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
}
  .gamePicker, .gamePicker * {
    color: #fff;
    font-size: 34px;
  }
  .gamePicker .pickerButton {
    background-color: #161616;
    color: #fff;
    margin: 10px 0px;
    width: 200px;
    height: 60px;
    line-height: 60px;
    font-size: 22px;
    font-weight: 600;
    text-align: center;
    border: 2px solid #e6e6e6;
    border-radius: 10px;
    box-shadow: 2px 2px 10px #000;
    cursor: pointer;
    transform: translateX(0);
    transition: all 0.4s;
  }
    .gamePicker .pickerButton:nth-of-type(1) {
      transition-delay: 0s;
    }
    .gamePicker .pickerButton:nth-of-type(2) {
      transition-delay: 0.1s;
    }
    .gamePicker .pickerButton.hidden {
      transform: translateX(-100vw);
    }

.logoLink {
  position: fixed;
  width: 160px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  text-decoration: none;
  color: #fff;
  font-weight: 600;
  font-size: 20px;
  text-align: center;
}
  .logoLink img {
    margin-top: 20px;
    filter: invert(100%);
    width: 100%;
    margin-bottom: 20px;
  }

.helpButton {
  position: absolute;
  z-index: 50;
  top: 5px;
  right: 10px;
  font-size: 30px;
  color: #fff;
  opacity: 0.4;
  cursor: pointer;
}

.backButton {
  position: absolute;
  z-index: 50;
  top: 5px;
  left: 10px;
  font-size: 30px;
  color: #fff;
  opacity: 0.4;
  cursor: pointer;
  transform: scale(1);
  transition: all 0.4s;
  transition-delay: 0.2s;
}
  .backButton.hidden {
    pointer-events: none;
    transform: scale(0);
  }

.guessGrid {
  display: flex;
  flex-direction: column;
  justify-content: space-between; 
  align-items: center;
}

.Guess {
  display: flex;
  align-items: center;
  transform: translateX(-30px);
}
    .Guess .compareSign {
      opacity: 0;
      transform: scale(0);
    }
    .Guess.guessed .compareSign {
      opacity: 1;
      transform: scale(1);
    }
    .Guess .Digit.hidden {
      opacity: 0;
      transform: rotate(-180deg);
    }
    .Guess:not(.guessed) .Digit {
      background-color: #073642;
    }
    .Digit.correct {
      background-color: #17d794;
    }
    .Digit.exists {
      background-color: #c69a00;
    }
    .Digit.wrong {
      background-color: #777;
    }
    .Guess .Digit .inner {
      transition: 0.3s all;
      opacity: 1;
    }
      .Guess .Digit .inner.hidden {
        opacity: 0;
        transform: rotate(-40deg);
      }

.Digit {
  width: 60px;
  height: 70px;
  line-height: 70px;
  text-align: center;
  font-size: 46px;
  font-weight: 600;
  border: 3px solid #586e75;
  border-radius: 6px;
  margin: 4px;
  color: #e6e6e6;
  opacity: 1;
  transition: 0.6s all;
}

.compareSign {
  width: 40px;
  height: 60px;
  line-height: 55px;
  margin: 0px 10px;
  text-align: center;
  background-color: #cb4b16;
  color: #fff;
  border: 2px solid #fff;
  font-size: 40px;
  font-weight: bold;
  border-radius: 20px;
  transition: 0.8s all;
  transition-timing-function: cubic-bezier(0.5, 2.8, 0.3, 1);
}
  .compareSign.lt {
    background-color: #cb4b16;
  }
  .compareSign.gt {
    background-color: #268bd2;
  }
  .compareSign.eq {
    background-color: #073642;
  }

.Numpad {
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  max-width: 380px;
}
  .Numpad .numButton {
    background: none;
    border: none;
    background-color: #161616;
    color: #eee;
    border: 2px solid #e6e6e6;
    border-radius: 8px;
    width: 60px;
    height: 42px;
    line-height: 42px;
    margin: 2px;
    font-size: 27px;
    font-weight: 600;
    cursor: pointer;
    text-align: center;
  }
    .Numpad .numButton.correct {
      background-color: #17d794;
    }
    .Numpad .numButton.exists {
      background-color: #c69a00;
    }
    .Numpad .numButton.wrong {
      background-color: #777;
    }
    .Numpad .numButton.numWide {
      width: 120px;
      font-size: 28px;
      margin-top: 14px;
      height: 44px;
    }
      .Numpad .numButton.numWide.enter {
        line-height: 41px;
      }

.PopMessage {
  position: fixed;
  z-index: 100;
  width: 300px;
  padding: 10px 20px;
  font-size: 20px;
  top: 160px;
  left: 50%;
  transform: translateX(-50%);
  background-color: #ff7777;
  color: #000;
  border: 2px solid #ff0000;
  border-radius: 20px;
  box-shadow: 1px 1px 10px #000000;
  opacity: 0.94;
  transition: 0.4s all;
}
  .PopMessage.hidden {
    opacity: 0;
    pointer-events: none;
  }

.EndGamePop {
  position: fixed;
  z-index: 100;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
  width: 95%;
  max-width: 500px;
  height: 95%;
  max-height: 700px;
  background-color: rgba(0,0,0,0.85);
  color: #fff;
  border: 4px solid #fff;
  border-radius: 20px;
  overflow: hidden;
  transition: 0.34s all;
  transform: translateX(-50%) scale(1);
}
  .EndGamePop.hidden {
    transform: translateX(-50%) scale(0);
    pointer-events: none;
  }
  .EndGamePop .title {
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    padding: 10px;
  }
    .EndGamePop .title.victory {
      background-color: #004433;
    }
    .EndGamePop .title.defeat {
      background-color: #440033;
    }
  .EndGamePop .popBox {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .EndGamePop .body {
    font-size: 24px;
    padding: 20px 35px;
    overflow-y: auto;
    border: 2px solid #888;
  }
    .EndGamePop .body .summaryStats {
      display: flex;
      align-items: center;
      justify-content: space-around;
    }
    .EndGamePop .body .statsBox {
      text-align: center;
      margin-top: 4px;
    }
      .EndGamePop .body .statsBox .stat {
        display: inline-block;
        font-weight: 600;
        margin-left: 20px;
        margin-right: 20px;
      }
    .EndGamePop .body .timeleftBox {
      display: flex;
      align-items: center;
      justify-content: space-around;
      margin-top: 10px;
      opacity: 1;
      transition: all 1s;
      font-size: 30px;
    }
      .EndGamePop .body .timeleftBox.hidden {
        opacity: 0;
      }
      .EndGamePop .body .timeleftBox .timeleft {
        font-size: 30px;
        font-weight: 600;
      }
  .EndGamePop .buttons {
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    justify-content: space-evenly;
    width: 100%;
  }
    .EndGamePop .buttons.short {
      margin-bottom: 0;
    }
    .EndGamePop .buttons * {
      width: 40%;
      height: 44px;
      line-height: 40px;
      text-align: center;
      font-size: 24px;
      font-weight: 600;
      color: #000;
      border: 2px solid #aaa;
      border-radius: 10px;
      cursor: pointer;
    }
    .EndGamePop .buttons .playAgain {
      background-color: #4444ff;
      color: #fff;
    }
    .EndGamePop .buttons .close {
      background-color: #ccc;
    }
    .EndGamePop .buttons .share {
      background-color: #2aa198;
    }

.StatBars {
  border: 1px solid #fff;
  padding: 12px;
}
  .StatBars .barsTitle {
    text-align: center;
  }
  .StatBars .statbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
    .StatBars .statbar .barBox {
      width: 140px;
      margin-left: 10px;
    }
    .StatBars .statbar .barBox .bar {
      margin-top: 4px;
      margin-bottom: 4px;
      padding-right: 4px;
      padding-top: 2px;
      padding-bottom: 2px;
      font-size: 18px;
      font-weight: 600;
      width: 100%;
      background-color: #4444ff;
      text-align: right;
    }

.InfoPop {
  position: fixed;
  display: flex;
  flex-direction: column;
  z-index: 100;
  left: 50%;
  top: 10px;
  transform: translateX(-50%);
  width: 95%;
  max-width: 500px;
  height: 95%;
  max-height: 700px;
  background-color: rgba(0,0,0,0.85);
  color: #fff;
  border: 4px solid #fff;
  border-radius: 20px;
  overflow: hidden;
  transition: 0.34s all;
  transform: translateX(-50%) scale(1);
}
  .InfoPop.hidden {
    transform: translateX(-50%) scale(0);
    pointer-events: none;
  }
  .InfoPop .title {
    text-align: center;
    font-size: 40px;
    font-weight: 600;
    padding: 10px;
    background-color: #222288;
  }
  .InfoPop .body {
    font-size: 24px;
    padding: 14px 35px;
    overflow-y: auto;
    margin-bottom: 10px;
    border: 2px solid #888;
  }
  .InfoPop .infoRow {
    display: flex;
    align-items: center;
    padding: 5px;
    border: 2px solid #444;
  }
  .InfoPop .closeButton {
    display: inline-block;
    margin-left: auto;
    margin-right: auto;
    margin-top: auto;
    margin-bottom: 14px;
    width: 80%;
    height: 44px;
    font-size: 24px;
    font-weight: 600;
    border-radius: 10px;
    cursor: pointer;
    color: #000;
    background-color: #ccc;
    text-align: center;
  }
  .InfoPop .compareSign {
    flex-shrink: 0;
  }
  .InfoPop .Digit {
    flex-shrink: 0;
    margin-left: 0;
    margin-right: 0;
  }
  .InfoPop .bodyDesc {
    text-align: center;
    margin-bottom: 14px;
  }
  .InfoPop .infoDesc {
    margin-left: 20px;
    margin-right: 10px;
    font-size: 22px;
  }

.SummaryImage {
  text-align: center;
  margin-top: 10px;
}
  .SummaryImage .img {
    display: inline-block;
    width: 160px;
  }


@media (max-height: 780px) {
  .Digit {
    height: 60px;
    line-height: 57px;
    font-size: 42px;
  }
}
@media (max-width: 480px) {
  .daily .SummaryImage .img {
    width: 140px;
  }

  .StatBars .statbar .barBox {
    width: 120px;
  }
}
@media (max-width: 450px) {
  .daily .SummaryImage .img {
    width: 120px;
  }

  .StatBars .statbar .barBox {
    width: 100px;
  }

  .gameTitle * {
    font-size: 52px;
  }

  .EndGamePop .body {
    font-size: 22px;
    padding: 10px 20px;
  }

  .EndGamePop .buttons * {
    font-size: 20px;
  }
}
@media (max-width: 380px) {
  .daily .SummaryImage .img {
    width: 110px;
  }

  .StatBars .statbar .barBox {
    width: 80px;
  }

  .Guess {
    transform: translateX(-17px);
  }

  .EndGamePop .body {
    font-size: 20px;
    padding: 6px 14px;
  }

  .Guess .Digit {
    width: 50px;
    height: 60px;
    line-height: 60px;
    font-size: 35px;
    border-width: 2px;
    margin: 1.2vw;
  }

  .Guess .compareSign {
    margin: 4px;
    font-size: 36px;
    width: 34px;
    height: 55px;
    line-height: 50px;
  }

  .Numpad {
    margin-top: 8px;
  }
}
@media (max-width: 348px) {
  .Guess {
    transform: translateX(-15px);
  }

  .EndGamePop .buttons * {
    font-size: 16px;
  }

  .Guess .compareSign {
    width: 30px;
    height: 50px;
    line-height: 50px;
  }

  .Numpad {
    max-width: 334px;
  }
    .Numpad .numButton {
      width: 54px;
      margin: 1px;
    }
}
@media (max-width: 300px) {
  .Guess {
    transform: translateX(-12px);
  }

  .Guess .Digit {
    width: 50px;
    height: 60px;
    line-height: 60px;
    font-size: 35px;
    border-width: 2px;
    margin: 2px;
  }

  .Guess .compareSign {
    width: 24px;
    font-size: 30px;
  }
}
