* {
  outline: none;
  user-select: none;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

html, body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

@font-face {
  font-family: 'Quicksand';
  src: url('./font/Quicksand/Quicksand.ttf') format('truetype');
  font-weight: 100 1000;
  font-stretch: 25% 151%;
}

@font-face {
  font-family: 'Chlorinar';
  src: url('./font/Chlorinar/Chlorinar.ttf') format('truetype');
}
